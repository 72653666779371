<template>
  <BPageLoader v-if="isLoading" class="flex-grow" />
  <div v-else>
    <div class="lg:hidden">
      <div class="px-6 py-2 lg:mt-32 flex flex-col font-semibold" >
    <header class="py-2 text-lg flex text-text-alternate-1 pr-6">
      <button @click="$router.go(-1)">
        <LeftArrowIcon class="h-6 fill-current" />
      </button>
      <h2 class="mx-auto">{{ faqDetails.question }}</h2>
    </header>
    <div class="text-sm font-medium py-5" v-html="faqDetails.answer" />
  </div>
    </div>
    <div class="hidden lg:flex justify-center pt-4">
      <div class="b-card mt-24 pt-8">
        <div class="px-26 py-2 flex flex-col font-semibold" >
    <header class="py-2 text-lg flex text-text-alternate-1 pr-6">
      <button @click="$router.go(-1)">
        <LeftArrowIcon class="h-6 fill-current" />
      </button>
      <h2 class="mx-auto">{{ faqDetails.question }}</h2>
    </header>
    <div class="text-sm font-medium py-5" v-html="faqDetails.answer" />
  </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useAppState } from '@/cmp-functions/appState.js';

export default {
  name: 'FAQDetails',

  setup() {
    const { params } = useRoute();
    const { faqs } = useAppState();
    const [faqId] = params.slug.split('-');

    const faqDetails = computed(() => faqs.data.find(({ id }) => id == faqId));

    return { ...toRefs(faqs), faqDetails };
  },
};
</script>
